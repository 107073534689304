import React from "react"
import { graphql } from "gatsby"

import "../style/styles.css"

// component
import Img from "gatsby-image"
import Layout from "../components/layout"

// gif
import mockupGIF from "../images/gif/mockup.gif"

// image-gallery
import ImageGallery from "react-image-gallery"

const ProcessPage = ({ data }) => {
  return (
    <Layout>
      <section className="w-full h-164 xs:h-164 pt-16">
        <div
          className="w-full h-164 absolute -mt-16 overflow-hidden"
          style={{ zIndex: "-1000" }}
        >
          <Img
            className="h-164"
            fluid={data.landing.edges[0].node.childImageSharp.fluid}
            alt="frame installation"
          />
        </div>
        <div className="container mx-auto px-5 h-full flex justify-center items-center text-gray-100">
          <div className="pb-10 xs:pb-12 text-center max-w-2xl text-sm pt-16">
            <h1 className="text-5xl">Our Process</h1>
            <p>
              To successfully complete our tasks, we have worked to develop a
              seamless approach to the design, engineering, manufacturing and
              installation of facades. Our 20+ years of experience allow us to
              take advantage of our solid knowledge in curtain walls and spatial
              geometry to develop a sophisticated production software to help
              make our clients' dreams come true.
            </p>
            <p>
              Curtain Wall is a complex business that demands a lot from the
              people who work on that field, but it is fascinating… The passion
              we all share at Alphacladding is the key to the satisfaction of
              our clients, and the door that will open to many opportunities in
              the future.
            </p>
          </div>
        </div>
      </section>
      <section className="w-full bg-green-600 pt-24 pb-12 px-5 xl:px-48 lg:px-24">
        <div className="container mx-auto px-10  xl:px-32 lg:px-20  pt-12 pb-8 h-full text-gray-800 bg-white rounded-lg text-center text-sm">
          <div className="">
            <h1 className="text-gray-800">Design</h1>
            <p>
              The façade is like the skin of a building; it’s the barrier
              between the inhabitant and the elements, and it can be made to
              interact freely with the exterior, assuring the best performance
              on the interior.
            </p>
            <p>
              The façade is also directly related to the look of the building,
              expressing its individuality. That is why our designers work
              hand-in-hand with architects in every possible way to exchange
              views and opinions while learning new things along the way.
            </p>
            <p>
              The more we communicate with the architectural team, the better
              results we reach as it relates to the beauty and the performance
              of our buildings.
            </p>
            <p>We also offer Design Assist for your convenience</p>
          </div>
          <div>
            <h1 className="text-gray-800">The Climate of a Building</h1>
            <div className="flex justify-between items-center sm:flex-col xs:flex-col">
              <div className="text-left w-8/12  sm:w-full xs:w-full">
                <p>
                  The beauty and finishes of a building are very important, but
                  the comfort of its users is a top priority in our industry.
                  Also, the interaction of our Façade and the impact of our
                  operations to the communities.
                </p>
                <p>
                  During the process of designing a façade system we pay
                  attention to the physical and environmental performance of our
                  buildings by combining the most advanced tools that are
                  available in the market (i.e. Thermal Simulation).
                </p>
              </div>
              <Img
                className="w-48"
                fluid={data.thermal.edges[0].node.childImageSharp.fluid}
                alt="thermal simultaion testing"
              />
            </div>
          </div>
        </div>
      </section>
      <section className="w-full pt-12 pb-20 px-5">
        <div className="container mx-auto px-10 xl:px-32  pt-12 pb-8 h-full text-gray-600 text-sm">
          <h1 className="text-gray-800 text-center xs:text-3xl xs:text-left">
            Industry Leading...
          </h1>
          <div className="">
            <h4 className="text-gray-800">Materials & Technology</h4>
            <p className="mb-4">
              One of the keys necessary for the success of every organization is
              to be up to date. New materials are implemented every day because
              science is always evolving.
            </p>
            <p>
              Alphacladding is also in tune with the advances of technology and
              we’re continuously expanding our use of new materials. As we
              continue to learn more about the new materials, we can have a
              wider spectrum of advice at the time of consultation with our
              clients. In order to keep this policy, we remain dedicated to
              researching and learning new developments in our field and
              applying them to our work.
            </p>
          </div>
          <div>
            <div className="flex justify-between items-start sm:flex-col xs:flex-col">
              <div className="text-left w-8/12  sm:w-full xs:w-full">
                <h4 className="text-gray-800">Software</h4>
                <p className="md:pr-8">
                  As we work hard to achieve excellence in our work, we are
                  devoted to the technological solutions that power our team and
                  allow us to offer our clients the very best results for every
                  project. We understand that when we invest in technology, the
                  latest software and systematic training, we instantly provide
                  our team with tools that will help them to succeed as we take
                  on new and more complex projects.
                </p>
                <div className="flex w-full mb-4 flex-wrap">
                  {data.software.edges.map(node => (
                    <Img
                      key={node.node.childImageSharp.fluid.src}
                      className="w-64 mr-2 mb-2"
                      fluid={node.node.childImageSharp.fluid}
                      alt="Software Badge"
                    />
                  ))}
                </div>
              </div>
              <Img
                className="w-64"
                fluid={data.mandt.edges[0].node.childImageSharp.fluid}
                alt="thermal simultaion testing"
              />
            </div>
          </div>
          <div>
            <div className="flex justify-between items-start md:flex-col sm:flex-col xs:flex-col mt-6">
              <div className="text-left w-8/12 md:w-full  sm:w-full xs:w-full">
                <h4 className="text-gray-800">Visual & Performance Mock-Ups</h4>
                <p className="">
                  Mock-Ups provide us with a deeper insight into the
                  effectiveness of our design before we move to production.
                </p>
                <p className="mb-2">
                  <b className="text-gray-800">Visual Mockups</b> are a very
                  important step to review and help the Design team and Owners
                  to visualize the final product. In this process we are able to
                  review the following elements:
                </p>
                <ul className="list-disc mb-4">
                  <li className="m-0">Glass composition</li>
                  <li className="m-0">Frit Patterns</li>
                  <li className="m-0">Aluminum Finishes</li>
                  <li className="m-0">Shadow box</li>
                  <li className="m-0">Paneling</li>
                  <li className="m-0">Stone Configuration</li>
                  <li className="m-0">Louvers, etc.</li>
                </ul>
                <p>
                  Also, we show interior elements like stool trims, column
                  covers, and other interior finishes that can help the Design
                  Team.
                </p>
              </div>
              <img
                src={mockupGIF}
                alt="testing of a frame"
                className="ml-4 md:m-0 sm:m-0 xs:m-0 rounded-lg"
              />
            </div>
            <p className="mt-4">
              <b className="text-gray-800">Performance Mock-Up</b> is a portion
              of the building which represents the existing conditions, in
              real-life size, built in a specialized testing facility. Testing a
              piece of the building gives us an idea of the behavior of our
              system and based on the test results, we can guarantee our design
              and performance. In order to ensure safety, all our products are
              strictly tested according with the Florida Building Code and the
              Miami Dade County product approval.
            </p>
          </div>
        </div>
      </section>
      <section className="w-full pt-12 pb-20 px-5 bg-gray-900">
        <div className="container mx-auto px-10 xl:px-32 pt-12 pb-8 h-full text-white text-sm">
          <div>
            <div className="flex justify-between items-start md:flex-col sm:flex-col xs:flex-col">
              <div className="text-left w-8/12 xl:mr-16 lg:mr-16 md:w-full sm:w-full xs:w-full">
                <h4>The Installation</h4>
                <p className="">
                  Installation is a complex part of a curtain wall system.
                  Alphacladding has an installation dream team with more than 35
                  years of experience in the business to help ensure a
                  successful installation process with every project. The
                  excellence of their work and expertise can turn any
                  challenging job into a simple task. Our installation crew is
                  one of the most experienced in the market.
                </p>
                <p>
                  We comply with OSHA requirements and all standards of safety,
                  which is the cornerstone of a successful installation process.
                </p>
              </div>
              <div className="w-64">
                <ImageGallery
                  items={data.slider.images}
                  showThumbnails={false}
                  showFullscreenButton={false}
                  showPlayButton={false}
                  autoPlay={true}
                  infinite={true}
                  showBullets={true}
                  showNav={false}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="w-full pt-24 pb-12 px-5">
        <div className="container mx-auto px-10 xl:px-32  pt-12 pb-8 h-full text-gray-800 text-sm">
          <div
            className="xl:w-64 xl:ml-128 xl:mt-24 lg:w-64 lg:ml-128 lg:mt-24 w-32 -mt-20 -ml-8 absolute overflow-hidden"
            style={{ opacity: 0.15 }}
          >
            <Img
              className=""
              fluid={data.leed.edges[0].node.childImageSharp.fluid}
              alt="leed green asosciate logo"
            />
          </div>
          <div className="text-gray-600">
            <h1 className="text-gray-800">Sustainability at Alphacladding</h1>
            <p className="mb-4">
              Alphacladding LLC. is a proud member of the US Green Building
              Council. For us, nothing is more important than always being on
              board with the latest sustainability measures and initiatives to
              reduce the impact of our activities on the environment.
              <br className="xs:hidden sm:hidden md:hidden" />
              An important part of our mission is to conduct our business based
              on these principles of sustainability:
            </p>
            <p className="tracking-widest uppercase text-gray-800 mb-2 font-semibold">
              Environmental
            </p>
            <p className="tracking-widest uppercase text-gray-800 mb-2 font-semibold">
              Social
            </p>
            <p className="tracking-widest uppercase text-gray-800 mb-2 font-semibold">
              Economic
            </p>
            <p>
              Those principles allow us to control our consumption of energy to
              ensure that we do not waste it. We are constantly working on
              reducing our waste by recycling materials we use in our office and
              implementing e-files, emails, and reducing our printed materials,
              which minimizes the use of paper and storage.
            </p>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export const query = graphql`
  query {
    landing: allFile(
      filter: { relativePath: { regex: "/images/landing/process/" } }
    ) {
      edges {
        node {
          childImageSharp {
            fluid(maxWidth: 4000) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
    thermal: allFile(
      filter: { relativePath: { regex: "/images/tech/thermal/" } }
    ) {
      edges {
        node {
          childImageSharp {
            fluid(maxWidth: 300) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
    mandt: allFile(
      filter: { relativePath: { regex: "/images/tech/materialsandtech/" } }
    ) {
      edges {
        node {
          childImageSharp {
            fluid(maxWidth: 300) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
    software: allFile(
      filter: {
        relativePath: { regex: "/images/tech/cad|3ds|bim|revit|Inventor/" }
      }
    ) {
      edges {
        node {
          childImageSharp {
            fluid(maxWidth: 100, quality: 100) {
              ...GatsbyImageSharpFluid
              ...GatsbyImageSharpFluidLimitPresentationSize
            }
          }
        }
      }
    }
    leed: allFile(
      filter: { relativePath: { regex: "/images/landing/leed/" } }
    ) {
      edges {
        node {
          childImageSharp {
            fluid(maxWidth: 4000) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
    slider: allFile(filter: { relativePath: { regex: "/images/process/" } }) {
      images: nodes {
        original: publicURL
      }
    }
  }
`

export default ProcessPage
